.loading-animation {
    width: 2rem;
    height: 2rem;
    position: relative;
}

.spinner {
    animation: rotate 2s linear infinite;
    width: 100%;
    height: 100%;
}

.path {
    stroke: #3498db;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}