@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BalooBhai2";
  font-weight: 900;
  src: local("BalooBhai2-VariableFont_wght"), url(./assets/fonts/BalooBhai2-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "BalooBhaiJaan2";
  src: local("BalooBhaijaan2-VariableFont_wght"), url(./assets/fonts/BalooBhaijaan2-VariableFont_wght.ttf) format("truetype");
}

@font-face {
  font-family: "BalooBhaiJaan2ExtraBold";
  src: local("BalooBhaijaan2-ExtraBold"), url(./assets/fonts/BalooBhaijaan2-ExtraBold.ttf) format("truetype");
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.App {
  background-color: #2B70EA;
  text-align: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* max-width: 1400px; */
  width: 100%;
  justify-content: center;
  padding: 0 0 0 0;
  position: relative;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes breath-left {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1) translateX(-20px) translateY(-10px);
  }

  100% {
    transform: scale(1);

  }
}

@keyframes breath-right {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1) translateX(20px) translateY(-10px);
  }

  100% {
    transform: scale(1);

  }
}

@keyframes breath-left-once {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.1) translateX(-60%) translateY(40px);
  }
}

@keyframes breath-right-once {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1.1) translateX(60%) translateY(40px);
  }
}

.breath-left-once {
  animation: breath-left-once 3s forwards;
}

.breath-right-once {
  animation: breath-right-once 3s forwards;
}

.breath-left {
  animation: breath-left infinite 2s forwards;
}

.breath-right {
  animation: breath-right infinite 2s forwards;
}

::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #FD8484 0%, #F06661 77%, #DF3F34 100%);
  border-radius: 50px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 0;
}