@keyframes shake {

    0%,
    100% {
        transform: translateX(0) rotate(0);
    }

    20%,
    60% {
        transform: translateX(-10px) rotate(-10deg);
    }

    40%,
    80% {
        transform: translateX(10px) rotate(10deg);
    }

    /* 10%,
    30%,
    50%,
    70%,
    90% {
        transform: translateX(-10px) rotate(-10deg);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translateX(10px) rotate(10deg);
    } */
}

.shake {
    animation: shake 2s ease-in-out infinite;
}

.shrink {
    transform: scale(0.5);
    transition: transform 1s ease-in-out;
}

.expand {
    transform: scale(1.5);
    transition: transform 1s ease-in-out;
}

@keyframes fountain {
    0% {
        opacity: 1;
        transform: translateY(600px) scale(0);
    }

    100% {
        opacity: 1;
        transform: translateY(100px) scale(3);
    }
}

@keyframes fountain-left {
    0% {
        opacity: 1;
        transform: translateY(600px) translateX(-150px) scale(0);
    }

    50% {
        transform: translateY(500px) translateX(-200) scale(1.5);
    }

    100% {
        opacity: 1;
        transform: translateY(100px) translateX(-250px) scale(3);
    }
}

@keyframes fountain-right {
    0% {
        opacity: 1;
        transform: translateY(600px) translateX(-50px) scale(0);
    }

    50% {
        transform: translateY(500px) translateX(0) scale(1.5);
    }

    100% {
        opacity: 1;
        transform: translateY(100px) translateX(100px) scale(3);
    }
}

.hidden {
    opacity: 0;
}

.fountain {
    bottom: 0;
    animation: fountain 5s ease-out forwards;
}

.fountain-left {
    animation: fountain-left 4s ease-out forwards;
}

.fountain-right {
    animation: fountain-right 4s ease-out forwards;

}

@keyframes ticketExpand {
    0% {
        opacity: 0;
        transform: translateY(-100px) scale(0);
    }

    100% {
        opacity: 1;
        transform: translateY(-350px) scale(1.5);
        z-index: 5;
    }
}

.ticket {
    bottom: 0;
    animation: ticketExpand 3s ease-out forwards;
}

@keyframes chunk_explosion {
    0% {
        transform: scale(0) translateY(0);
    }

    20% {
        transform: scale(0) translateY(-20vh);

    }

    50% {
        transform: scale(0) translateY(-30vh);
    }

    100% {
        transform: scale(1.5) translateY(-40vh);
    }
}


.chunk_explosion {
    animation: chunk_explosion 3.5s forwards;
}

.scale0 {
    transform: scale(0);
}